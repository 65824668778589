import logo from "../assets/img/header/logo.svg";
import uaFlag from "../assets/img/header/ua.svg";
// import "../assets/styles/sections/header.scss";

export default function Header({ data, state, switchLanguage }) {
	return (
		<header class="header">
			<div class="container">
				<a href="/" class="header__logo">
					<img src={logo} alt="" />
				</a>
				<a href="#donate" class="header__btn">
					{data.donateHeader}
					<img src={uaFlag} alt="" />
				</a>
				<ul class="header__nav">
					<li>
						<a href="">{data.whatWeDoHeader}</a>
					</li>
					<li>
						<a href="">{data.focusHeader}</a>
					</li>
					<li>
						<a href="">{data.transferHeader}</a>
					</li>
				</ul>
				<ul class="header__langs">
					<li>
						<a>{state.toUpperCase()}</a>
						<ul class="sub-menu">
							<li onClick={() => switchLanguage(state === "ua" ? "en" : "ua")}>
								<a>{state === "ua" ? "EN" : "UA"}</a>
							</li>
						</ul>
					</li>
				</ul>
				<a href="#join-us" class="btn btn--border btn--lg">
					{data.joinButton}
				</a>
				{/* <div class="header__menu-btn">
					<span></span>
					<span></span>
					<span></span>
				</div> */}
			</div>
		</header>
	);
}
