export const whatWeDoUa = {
	title1: `ЯК ДОПОМАГАЄМО НАШИМ`,
	title2: `ЗАХИСНИКАМ?`,
	title3: `Є ДВА ОСНОВНИХ НАПРЯМКИ НАШОЇ РОБОТИ`,
	card1_1: `Оперативно-тактичне спорядження`,
	card1_2: `Підрозділи: ЗСУ, НГУ, спецпідрозділи ССО, спецпризначенці
  ГУР.`,
	card1_3: `Забезпечення зі сторони нашої організації (фото
  підтвердження):`,
	card1_chips: [
		`Оптичні приціли`,
		`Тепловізори`,
		`Амуніція`,
		`Прилади нічного бачення`,
		`Елементи протикумулятивного захисту`,
	],
	card1_units_title: "Голова ГО Олег Корчак",
	card1_units: ["ЗСУ", "НГУ", "спецпідрозділи ССО"],
	card2_1: `Гуманітарна допомога`,
	card2_2: "Гуманітарна допомога підрозділам ЗСУ:",
	card2_chips: [
		"Спеціалізові засоби зв’язку",
		"Ремонт & Модернізація техніки ЗСУ",
		"Транспортні засоби",
		"Термобілизна",
		"Допомога добровольчим батальйонам",
		"Тактична медицина",
		"Маскувальні сітки",
	],
	card2_units_title: "Підрозділи:",
	card2_units: ["ЗСУ", "НГУ", "спецпідрозділи ССО"],
};
