export const promoEn = {
	header1: "Non-Governmental",
	header2: "Organisation",
	header3: "“ASSISTANCE TO THE ARMY OF UKRAINE",
	header4: "KYIV”",
	card1_1: "Your help brings Ukraine closer to victory",
	card1_2: "Support Us",
	card2_1: `Our organization carries out volunteer activities 
  aimed to support and supply the needs of the Ukrainian Armed Forces.`,
	card3_1: `We have built reliable and complete logistics:`,
	card3_2: `we search, purchase and deliver supplies to our defenders.`,
	card3_3: `What we are raise for`,
	card4_1: `We have been operating since 2014, when russish first attacked Ukraine.`,
	card5_1: "Keep fighting – you are sure to win!",
	card5_2: "Taras Shevchenko",
};
