import contactIcon1 from "../assets/img/contacts/contact-icon-1.svg";
import contactIcon2 from "../assets/img/contacts/contact-icon-2.svg";

export default function Contacts({ data }) {
	return (
		<section class="contacts pt-lg pb-lg" id="join-us">
			<div class="container">
				<h2 class="contacts__title">{data.title}</h2>
				<p class="contacts__subtitle">{data.subtitle}</p>
				<div class="contacts__btns">
					<a
						href="tg://resolve?domain=oleg_uarmy"
						class="btn btn--border btn--xl"
					>
						<img src={contactIcon1} alt="" />
						{data.contactUs}
					</a>
					<a href="https://wa.me/380672399393" class="btn btn--yellow btn--xl">
						<img src={contactIcon2} alt="" />
						{data.contactUs}
					</a>
				</div>
			</div>
		</section>
	);
}
