export const focusUa = {
	text0: "зІБРАНО",
	text1: "Головний фокус на",
	text2: "травень-червень 2022",
	text3: "Центр підготовки військових операторів дронів.",
	text4: "навчених",
	text5: "операторів бпла",
	text6: "виконаних",
	text7: "бойових завдань",
	text8: "Головний фокус на",
	text9: "жовтень-грудень 2022",
	text10: "Зимове забезпечення підрозділів ЗСУ.",
	text11: "Термобілизна, польові речі, термошкарпетки, утеплювачі та ін.",
	text12: "Головний фокус на",
	text13: "січень 2023 - зараз",
	text14: "Збираємо на вище описані речі",
};
