import logo1 from "../assets/img/footer/logo-1.png";
import logo2 from "../assets/img/footer/logo-2.png";
import logo3 from "../assets/img/footer/logo-3.png";
import logo4 from "../assets/img/footer/logo-4.png";
import logo5 from "../assets/img/footer/logo-5.png";
import logo6 from "../assets/img/footer/logo-6.png";
import logo7 from "../assets/img/footer/logo-7.png";
import logo8 from "../assets/img/footer/logo-8.png";

export default function Footer({ data, state }) {
	return (
		<footer class="footer pt-lg pb-lg">
			<div class="container">
				<ul class="footer__list">
					<li>
						<img src={logo1} alt="" />
					</li>
					<li>
						<img src={logo2} alt="" />
					</li>
					<li>
						<img src={logo3} alt="" />
					</li>
					<li>
						<img src={logo4} alt="" />
					</li>
					<li>
						<img src={logo8} alt="" />
					</li>
					<li>
						<img src={logo5} alt="" />
					</li>
					<li>
						<img src={logo6} alt="" />
					</li>
					<li>
						<img src={logo7} alt="" />
					</li>
				</ul>
			</div>
		</footer>
	);
}
