export const targetUa = {
	title1: "НА ЩО ЗБИРАЄМО ГРОШІ",
	title2: "",
	supportButton: "Підтримати",
	card1_1: "Каски",
	card1_2: "захисні",
	card1_3: "кевларові",
	card2_1: "Бронежилети",
	card2_2: "від 4-го",
	card2_3: "рівня захисту",
	card2_4: "і вище",
	card3_1: "Термобілізна",
	card3_2: "буржуйки",
	card4Texts: [
		{ text: "Тепловізори", class: "green" },
		{ text: "та Прилади", class: "" },
		{ text: "Нічного Бачення", class: "green" },
	],
	card5_1: "Оптика",
	card5_2: "для зброї",
	card6_1: "Пікапи",
};
