export const whatWeDoEn = {
	title1: `HOW DO WE SUPPORT`,
	title2: `ARMED FORCES?`,
	title3: `THERE ARE TWO MAIN ASPECTS OF OUR WORK`,
	card1_1: `Operational & Tactical Equipment`,
	card1_2: `Military Branches: Ukrainian Ground Forces (UGF),
  National Guard of Ukraine, 
  Special Operations Forces, 
  Chief Directorate of Intelligence.`,
	card1_3: `Our Organization Supplies (photo confirmations):`,
	card1_chips: [
		"Anti-cumulative protection shields",
		"Ammunition sets",
		"Night vision devices",
		"Thermal Imagers",
		"Specialized military optics",
	],
	card1_units_title: "Our CEO Oleh Korchack",
	card1_units: [
		"UGF",
		"National Guard of Ukraine",
		"Special Operations Forces",
	],
	card2_1: `Humanitarian Aid`,
	card2_2: "Humanitarian Aid to Branches of the UGF:",
	card2_chips: [
		"Specialized communications equipment",
		"Tactical medicine",
		"Camouflage nets",
		"Supplying of volunteer battalions since 2014",
		"Repair & modernization of the UGF equipment",
		"Thermal underwear",
		"Light tactical vehicles",
	],
	card2_units_title: "Military Branches:",
	card2_units: [
		"UGF",
		"National Guard of Ukraine",
		"Special Operations Forces",
	],
};
