export const promoUa = {
	header1: "Громадська",
	header2: "організація",
	header3: "“ДОПОМОГА АРМІЇ УКРАЇНИ",
	header4: "КИЇВ”",
	card1_1: "Ваша допомога наближає Україну до перемоги",
	card1_2: "Підтримати",
	card2_1: `Ми - організація, яка здійснює волонтерську діяльність,
  спрямовану на забезпечення потреб Збройних Сил України та інших
  силових структур нашої держави.`,
	card3_1: `Побудували надійну логістику від А до Я:`,
	card3_2: `ми відшукуємо, закупляємо та доставляємо замовлення військовим.`,
	card3_3: `На що збираємо зараз`,
	card4_1: `Ведемо нашу діяльність, починаючи з 2014 року, від самого
  початку російської агресії проти України.`,
	card5_1: "Борітеся – поборете!",
	card5_2: "Тарас Шевченко",
};
