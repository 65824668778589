export default function Target({ data, state }) {
	return (
		<section class="target pt-lg pb-lg" id="target">
			<div class="container">
				<div class="target__header">
					<h2 class="target__header-title">
						{data.title1}&nbsp;{data.title2}
					</h2>
					<a href="#account-details" class="btn btn--border btn--lg">
						{data.supportButton}
					</a>
				</div>
				<ul class="target__list">
					<li class="target__item">
						<div class="target__number">1.</div>
						<ul class="target__title">
							<li>{data.card1_1}</li>
							<li>
								<span class="black">{data.card1_2}</span>
							</li>
							<li>{data.card1_3}</li>
						</ul>
					</li>
					<li class="target__item end">
						<div class="target__number">2.</div>
						<ul class="target__title">
							<li>{data.card2_1}</li>
							<li>
								<span class="purpure">{data.card2_2}</span>
							</li>
							<li>{data.card2_3}</li>
							<li>{data.card2_4}</li>
						</ul>
					</li>
					<li class="target__item">
						<div class="target__number">3.</div>
						<ul class="target__title">
						<li>
								<span class="yellow">{data.card3_1}</span>
							</li>
							<li>
								<span class="yellow">{data.card3_2}</span>
							</li>
						</ul>
					</li>
					<li class="target__item end">
						<div class="target__number">4.</div>
						<ul class="target__title">
							{data.card4Texts.map((x) => (
								<li>
									<span class={x.class}>{x.text}</span>
								</li>
							))}
							{/* <li>
								<span class="">{data.card4_1}</span>
							</li>
							<li>
								<span class="green">{data.card4_2}</span>
							</li> */}
						</ul>
					</li>
					<li class="target__item">
						<div class="target__number">5.</div>
						<ul class="target__title">
							<li>{data.card5_1}</li>
							<li>{data.card5_2}</li>
						</ul>
					</li>
					<li class="target__item">
						<div class="target__number">6.</div>
						<div class="target__title">{data.card6_1}</div>
					</li>
				</ul>
				<div class="target__footer">
					<a href="#account-details" class="btn btn--border btn--lg">
						{data.supportButton}
					</a>
				</div>
			</div>
		</section>
	);
}
