export default function AccountDetails({ data }) {
	const paypalData = [
		{
			label: "PAYPAL",
			value: "uarmy.help2022@gmail.com",
		},
	];

	return (
		<section class="account-details pt-lg pb-lg" id="account-details">
			<div class="container">
				<div class="account-details__container">
					<div class="account-details__header">
						<span class="account-details__title-row">
							<h2 class="">{data.title}</h2>
							<svg
								width="60"
								height="60"
								viewBox="0 0 60 60"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="30" cy="38" r="22" fill="#F4E27F" />
								<g mask="url(#mask0_229_3011)">
									<path
										d="M21.9946 19.2509C21.9946 18.4993 21.3878 17.8926 20.6362 17.8926H20.1971L20.3374 4.32307C20.3465 3.57147 19.7443 2.96023 18.9927 2.95117C18.9882 2.95117 18.9836 2.95117 18.9791 2.95117C18.2366 2.95117 17.6298 3.54883 17.6208 4.2959L17.4804 17.8926H17.0141C16.2625 17.8926 15.6558 18.4993 15.6558 19.2509C15.6558 20.0025 16.2625 20.6093 17.0141 20.6093H18.8116C18.8161 20.6093 18.8206 20.6093 18.8252 20.6093H20.6362C21.3878 20.6093 21.9946 20.0025 21.9946 19.2509Z"
										fill="#0A0A0B"
									/>
									<path
										d="M36.5332 39.5302C36.5377 39.5302 36.5423 39.5257 36.5468 39.5257C36.5649 39.5167 36.5785 39.5076 36.5966 39.5031C36.6554 39.4759 36.7098 39.4487 36.7596 39.4125C36.7822 39.3989 36.8003 39.3854 36.8184 39.3718C36.8682 39.3356 36.9135 39.2993 36.9588 39.2541C36.9724 39.2405 36.986 39.2314 36.9995 39.2178C37.0539 39.1635 37.1037 39.1001 37.149 39.0367C37.158 39.0231 37.1671 39.005 37.1761 38.9914C37.2078 38.9371 37.2395 38.8873 37.2622 38.8284C37.2712 38.8058 37.2803 38.7832 37.2893 38.7605C37.312 38.7017 37.3301 38.6383 37.3482 38.5749C37.3527 38.5613 37.3572 38.5432 37.3618 38.5296C37.3754 38.4617 37.3844 38.3893 37.3889 38.3213L37.8417 26.9296C37.8643 26.3953 37.57 25.9018 37.0946 25.6619C36.6192 25.4219 36.0487 25.4853 35.6322 25.8158C33.9388 27.1832 31.8289 27.9348 29.6918 27.9348C26.6447 27.9348 23.7469 26.4225 21.9358 23.8915C21.5012 23.2803 20.65 23.1399 20.0387 23.5791C19.4275 24.0138 19.2871 24.865 19.7263 25.4762C20.2651 26.2323 20.8854 26.9115 21.5646 27.5182L21.9947 38.3213C22.0173 38.8556 22.3479 39.331 22.8459 39.5302L24.2993 40.1098L25.1007 57.2834H22.9003C22.1487 57.2834 21.5419 57.8901 21.5419 58.6417C21.5419 59.3933 22.1487 60 22.9003 60H36.4834C37.235 60 37.8417 59.3933 37.8417 58.6417C37.8417 57.8901 37.235 57.2834 36.4834 57.2834H34.2829L35.0798 40.1098L36.5332 39.5302Z"
										fill="#0A0A0B"
									/>
									<path
										d="M45.5387 12.9893H38.2943C37.5427 12.9893 36.936 13.596 36.936 14.3476V18.2822C36.936 21.1074 38.8648 23.5117 41.6222 24.1229C41.7173 24.1455 41.8169 24.1546 41.9165 24.1546C42.0161 24.1546 42.1157 24.1455 42.2108 24.1229C44.9682 23.5117 46.897 21.1074 46.897 18.2822V14.3476C46.897 13.596 46.2903 12.9893 45.5387 12.9893Z"
										fill="#0A0A0B"
									/>
									<path
										d="M29.6918 24.6838C31.6922 24.6838 33.3139 23.0621 33.3139 21.0616C33.3139 19.0612 31.6922 17.4395 29.6918 17.4395C27.6913 17.4395 26.0696 19.0612 26.0696 21.0616C26.0696 23.0621 27.6913 24.6838 29.6918 24.6838Z"
										fill="#0A0A0B"
									/>
								</g>
							</svg>
						</span>
						{/* <a href="#account-details" class="btn btn--border btn--lg">
						{data.supportButton}
					</a> */}
					</div>
					<div class="account-details__row">
						<div class="account-details__col">
							<div class="account-details__panel">
								<h3 class="account-details__panel-title">{data.title1}</h3>
								{data.swiftData.map((item, idx) => (
									<div class="account-details__item" key={idx}>
										<div class="account-details__item-header">
											<div class="account-details__item-label">
												{item.label}
											</div>
											{item.copy && (
												<button
													onClick={() => {
														navigator.clipboard.writeText(item.copyValue);
													}}
													class="copy-button"
												>
													Copy
												</button>
											)}
										</div>
										<div class="account-details__item-value">{item.value}</div>
									</div>
								))}
							</div>
							<div class="account-details__panel">
								<h3 class="account-details__panel-title">{data.title2}</h3>
								{data.entityData.map((item, idx) => (
									<div class="account-details__item" key={idx}>
										<div class="account-details__item-header">
											<div class="account-details__item-label">
												{item.label}
											</div>
											{item.copy && (
												<button
													onClick={() => {
														navigator.clipboard.writeText(item.copyValue);
													}}
													class="copy-button"
												>
													Copy
												</button>
											)}
										</div>
										<div class="account-details__item-value">{item.value}</div>
									</div>
								))}
							</div>
						</div>
						<div class="account-details__col">
							<div class="account-details__panel">
								<h3 class="account-details__panel-title">Payment in UAH</h3>
								<div class="account-details__items">
									{data.uahPaymentData.map((item, idx) => (
										<div class="account-details__item" key={idx}>
											<div class="account-details__item-header">
												<div class="account-details__item-label">
													{item.label}
												</div>
												{item.copy && (
													<button
														onClick={() => {
															navigator.clipboard.writeText(item.copyValue);
														}}
														class="copy-button"
													>
														Copy
													</button>
												)}
											</div>
											<div class="account-details__item-value">
												{item.value}
											</div>
										</div>
									))}
								</div>
							</div>
							<div class="account-details__panel">
								{paypalData.map((item, idx) => (
									<div class="account-details__item accent" key={idx}>
										<div class="account-details__item-label">{item.label}</div>
										<div class="account-details__item-value">{item.value}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
