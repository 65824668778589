export const accountDetailsUa = {
	title: "РЕКВІЗИТИ",
	title1: "Фіз. Особа",
	title2: "Юр. Особа",
	swiftData: [
		{
			label: "Отримувач",
			value:
				"KORCHAK OLEH, ST. ROKOSOVSKOHO #4, UNIT #169, KYIV, 01001, UKRAINE",
		},
		{
			label: "IBAN (USD)",
			value: "UA 18 322001 00000 2620 8326 7543 65",
			copy: true,
			copyValue: "UA183220010000026208326754365",
		},
		{
			label: "IBAN (EUR)",
			value: "UA 45 322001 00000 2620 1326 7693 46",
			copy: true,
			copyValue: "UA453220010000026201326769346",
			
		},
		{
			label: "БАНК",
			value: "JSC UNIVERSAL BANK",
		},
		{
			label: "SWIFT Банку",
			value: "UNJSUAUKXXX",
		},
		{
			label: "Призначення платежу",
			value: "Charitable donation to Ukraine",
		},
	],
	uahPaymentData: [
		{
			label: "Monobank (UAH)",
			value: "5375 4114 1074 5496",
		},
		{
			label: "Monobank (USD)",
			value: "5375 4188 1075 9175",
		},
		{
			label: "Monobank (EUR)",
			value: "5375 4199 0757 4766",
		},
		{
			label: "UAH IBAN",
			value: "UA 30 322001 00000 2620 5326 6668 54",
			copy: true,
			copyValue: "UA303220010000026205326666854",
		},
		{
			label: "Отримувач",
			value: "Корчак Олег Вадимович",
		},
		{
			label: "ЄДРПОУ",
			value: "2889017717",
		},
		{
			label: "Банк",
			value: 'АТ "УНIВЕРСАЛ БАНК"',
		},
	],
	entityData: [
		{
			label: "Назва отримувача",
			value: "БО «БФ  ДОПОМОГА АРМІЇ УКРАЇНИ – КИЇВ»",
		},
		{
			label: "Адреса отримувача",
			value: "01001, УКРАЇНА, М. КИЇВ, ВУЛ. ПЕТРА ВЕРШИГОРИ, Б. 1",
		},
		{
			label: "USD IBAN Code",
			value: "UA 71 305299 00000 2600 4036 2398 33",
			copy: true,
			copyValue: "UA713052990000026004036239833",
		},
		{
			label: "EUR IBAN Code",
			value: "UA 47 305299 00000 2600 8036 2307 20",
			copy: true,
			copyValue: "UA473052990000026008036230720",
		},
		{
			label: "Назва банку",
			value: 'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE',
		},
		{
			label: "SWIFT code банку",
			value: "PBANUA2X",
		},
		{
			label: "Призначення платежу",
			value: "CHARITABLE DONATION",
		},
	],
};
