import { useState } from "react";
import "./assets/styles/main.scss";
import Donate from "./components/Donate";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Promo from "./components/Promo";
import Focus from "./components/Focus";
import Contacts from "./components/Contacts";
import WhatWeDo from "./components/WhatWeDo";
import AccountDetails from "./components/AccountDetails";
import Target from "./components/Target";
import { headerUa } from "./assets/data/ua/header-ua";
import { headerEn } from "./assets/data/en/header-en";
import { promoUa } from "./assets/data/ua/promo-ua";
import { promoEn } from "./assets/data/en/promo-en";
import { donateUa } from "./assets/data/ua/donate-ua";
import { donateEn } from "./assets/data/en/donate-en";
import { whatWeDoEn } from "./assets/data/en/what-we-do-en";
import { whatWeDoUa } from "./assets/data/ua/what-we-do-ua";
import { targetEn } from "./assets/data/en/target-en";
import { targetUa } from "./assets/data/ua/target-ua";
import { contactsEn } from "./assets/data/en/contacts-en";
import { contactsUa } from "./assets/data/ua/contacts-ua";
import { focusEn } from "./assets/data/en/focus-en";
import { focusUa } from "./assets/data/ua/focus-ua";
import { accountDetailsEn } from "./assets/data/en/account-details-en";
import { accountDetailsUa } from "./assets/data/ua/account-details-ua";

function App() {
	const [language, setlanguage] = useState("ua");

	return (
		<>
			<div class="wrapper">
				<Header
					data={language === "ua" ? headerUa : headerEn}
					state={language}
					switchLanguage={setlanguage}
				/>
				<main>
					<Promo data={language === "ua" ? promoUa : promoEn} />
					<Donate data={language === "ua" ? donateUa : donateEn} />
					<WhatWeDo data={language === "ua" ? whatWeDoUa : whatWeDoEn} />
					<Target data={language === "ua" ? targetUa : targetEn} />
					<Focus data={language === "ua" ? focusUa : focusEn} />
					<AccountDetails
						data={language === "ua" ? accountDetailsUa : accountDetailsEn}
					/>
					<Contacts data={language === "ua" ? contactsUa : contactsEn} />
				</main>
				<Footer />
			</div>
		</>
	);
}

export default App;
