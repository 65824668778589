export const donateUa = {
	tab1_header: "Про подарунок за донати",
	tab1_card1_1: "Маленький шматочок історичних українських перемог",
	tab1_card1_2: `Це шматочок російського військового літака СУ –
  25СМ (фронтовий штурмовик Front-line bomber), який був
  демілітаризований Збройними Силами України.`,
	tab1_card2_1: `Цей бойовий літак з заводським номером`,
	tab1_card2_2: `завдавав ударів по підрозділам Збройних Сил України,
  об’єктам цивільної інфраструктури, підприємствам та
  житловим будинкам в селищах і містах Київської області в
  лютому-березні 2022 року.`,
	tab1_card2_3: `Саме він причетний до злочинних руйнувань таких міст
  Київської області, як – Буча, Ірпінь, Макарів та Бузова.`,
	tab1_card3_1: `Літак був знищений`,
	tab1_card3_2: `завдяки майстерним і відважним діям Збройних Сил України в
  Макарівському районі Київської області під час запеклих
  боїв з оборони Києва та захисту незалежності і
  територіальної цілісності України.`,
	tab1_bottom: `Ваші донати на підтримку і забезпечення Збройних Сил України
  допоможуть їм знищити ще більше подібних машин смерті і
  руйнувань, зберегти життя українців та захистити весь
  цивілізований світ від просування російських окупантів далі і
  далі.`,
	tab1_next_button: "Типи подарунків",
	tab2_header: "Типи подарунків ",
	tab2_center_card_1: "Вже доступні:",
	tab2_center_card_2: "Незабаром",
	tab2_center_card_3: `■ Тип E – частини корпусу з найунікальнішими маркуваннями
  найбільшого розміру.`,
	tab2_type_a_title: "Тип А",
	tab2_type_a_1: "шматочок корпусу літака маленького розміру.",
	tab2_type_b_title: "Тип B",
	tab2_type_b_1: "шматочок корпусу літака середнього розміру",
	tab2_type_c_title: "Тип C",
	tab2_type_c_1: "шматочок корпусу літака середнього розміру з маркуванням",
	tab2_type_c_2: `З унікальними маркуваннями, написами, ідентифікаційними
  номерами, нанесеними технічним персоналом окупантів.`,
	tab2_type_d_title: "Тип D",
	tab2_type_d_1: "шматочок корпусу літака великого розміру",
	tab2_type_d_2: `З унікальними маркуваннями, написами, ідентифікаційними
  номерами, нанесеними технічним персоналом окупантів.`,
	tab2_next_button: "Як отримати подарунок?",
	tab3_header: "Як отримати подарунок?",
	tab3_card1_1: `Кожен долар ваших донатів дуже важливий для нас, і всі кошти в
  повному обсязі йдуть на забезпечення потреб Збройних Сил
  України та наближають перемогу над російськими окупантами. `,
	tab3_card1_2: `Але найщедрішим благодійникам ми даруємо подарунок -
  «Маленький шматочок історичних українських перемог»`,
	tab3_card500_1: `Благодійники, які задонатять`,
	tab3_card500_2: `Отримають подарунок`,
	tab3_card500_3: `«Маленький шматочок історичних українських перемог» тип А`,
	tab3_card500_4: `і більше`,
	tab3_card1000_1: `Благодійники, які задонатять`,
	tab3_card1000_2: `Отримають подарунок`,
	tab3_card1000_3: `«Маленький шматочок історичних українських перемог» тип B`,
	tab3_card1000_4: `і більше`,
	tab3_card2000_1: `Благодійники, які задонатять`,
	tab3_card2000_2: `Отримають подарунок`,
	tab3_card2000_3: `«Маленький шматочок історичних українських перемог» тип C`,
	tab3_card2000_4: `і більше`,
	tab3_card5000_1: `Благодійники, які задонатять`,
	tab3_card5000_2: `Отримають подарунок`,
	tab3_card5000_3: `«Маленький шматочок історичних українських перемог» тип D`,
	tab3_card5000_4: `і більше`,
	tab3_card2_1: `Донати ви можете внести за будь-якими зручними для вас
  реквізитами, вказаними на нашому сайті, після цього напишіть
  на нашу пошту uarmy.help2022@gmail.com адресу, куди надіслати
  подарунок і данні отримувача.`,
};
