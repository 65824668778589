export const targetEn = {
	title1: "What do we Raise for",
	title2: "",
	supportButton: "Support Us",
	card1_1: "Kevlar",
	card1_2: "Ballistic",
	card1_3: "Helmets",
	card2_1: "Tactical Vests",
	card2_2: "4th level+",
	card3_1: "Thermal clothing,",
	card3_2: "Potbelly Stoves",
	card4Texts: [
		{ text: "Thermal Imaging &", class: "green" },
		{ text: "Night Vision Devices", class: "green" },
	],
	card5_1: "Optics",
	card5_2: "for weapons",
	card6_1: "Trucks",
};
