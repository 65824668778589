import React from "react";
import ReactDOM from "react-dom";
// import "./assets/styles/index.scss"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
	<React.StrictMode>
		{/*<HelmetProvider>*/}
		<App />
		{/*</HelmetProvider>*/}
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
