import wayIcon1 from "../assets/img/what-we-do/way-icon-1.png";
import wayIcon2 from "../assets/img/what-we-do/way-icon-2.svg";
import wayCardImg1 from "../assets/img/what-we-do/way-card-img-1.jpeg";
import wayCardImg2 from "../assets/img/what-we-do/way-card-img-2.jpeg";
import wayCardImg3 from "../assets/img/what-we-do/way-card-img-3.jpeg";

export default function WhatWeDo({ data }) {
	return (
		<section class="way pt-lg pb-lg">
			<div class="container">
				<div class="way__header">
					<h2 class="way__title">
						{data.title1}&nbsp;{data.title2}
					</h2>
					<p class="way__subtitle">{data.title3}</p>
				</div>
				<ul class="way__list">
					<li class="way__item">
						<article class="way-card">
							<div class="way-card__content">
								<h4 class="way-card__title">
									<img src={wayIcon1} alt="" />
									{data.card1_1}
								</h4>
								<div class="way-card__text">
									<p>{data.card1_2}</p>
									<p>{data.card1_3}</p>
								</div>
								<ul class="way-card__list">
									{data.card1_chips.map((c, i) => (
										<li class={"way-card__list-item "}>{c}</li>
									))}
								</ul>
							</div>
							<div class="way-card__img">
								<img src={wayCardImg1} alt="" />
								<div class="way-card__label">
									{data.card1_units_title} <br />
									{/* {data.card1_units.join(", ")} */}
								</div>
							</div>
						</article>
					</li>
					<li class="way__item">
						<article class="way-card">
							<div class="way-card__content">
								<h4 class="way-card__title">
									<img src={wayIcon2} alt="" />
									{data.card2_1}
								</h4>
								<p class="way-card__subtitle">{data.card2_2}</p>
								<ul class="way-card__list">
									{data.card2_chips.map((c, i) => (
										<li class={"way-card__list-item "}>{c}</li>
									))}
								</ul>
							</div>
							<div class="way-card__img">
								<div style={{ display: "flex", gap: 15 }}>
									<div>
										<img src={wayCardImg2} style={{ maxHeight: 400 }} alt="" />
									</div>
									<div>
										<img src={wayCardImg3} style={{ maxHeight: 400 }} alt="" />
									</div>
								</div>
							</div>
						</article>
					</li>
				</ul>
			</div>
		</section>
	);
}
