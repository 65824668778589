import focusIcon from "../assets/img/focus/focus-icon.svg";

export default function Focus({ data }) {
	return (
		<section class="focus pt-lg pb-lg">
			<div class="container">
				<div class="focus__inner">
					<div class="focus__header">
						<h2 class="focus__title">
							{data.text12}&nbsp;{data.text13}
						</h2>
						<p class="focus__subtitle">{data.text14}</p>
						{/* <p class="focus__subtitle" style={{ fontSize: 16 }}>
							{data.text11}
						</p> */}
					</div>
				</div>
				<div class="focus__inner">
					<div class="focus__header">
						<div class="focus__label">
							<img src={focusIcon} alt="" /> {data.text0}
						</div>
						<h2 class="focus__title">
							{data.text8}&nbsp;{data.text9}
						</h2>
						<p class="focus__subtitle">{data.text10}</p>
						<p class="focus__subtitle" style={{ fontSize: 16 }}>
							{data.text11}
						</p>
					</div>
				</div>
				<div class="focus__inner">
					<div class="focus__header">
						<div class="focus__label">
							<img src={focusIcon} alt="" /> {data.text0}
						</div>
						<h2 class="focus__title">
							{data.text1}
							<br />
							{data.text2}
						</h2>
						<p class="focus__subtitle">{data.text3}</p>
					</div>
					<ul class="focus__stats">
						<li class="focus__stats-item lg">
							<div class="focus__stats-item-title">500+</div>
							<div class="focus__stats-item-text">
								{data.text4} <br /> {data.text5}
							</div>
						</li>
						<li class="focus__stats-item md">
							<div class="focus__stats-item-title">10000+</div>
							<div class="focus__stats-item-text">
								{data.text6} <br /> {data.text7}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}
