export const donateEn = {
	tab1_header: "Presents to our top donators",
	tab1_card1_1: "A tiny memorial piece of Ukrainian victories",
	tab1_card1_2: `This is a small fraction of “demilitarized” 
  russian Sukhoi SU-25SM (front-line bomber) by Ukrainian Armed Forces.`,
	tab1_card2_1: `The presented jet has a unique serial number`,
	tab1_card2_2: `During the very beginning of russian invasion 
  in February-March, this bomber was operating in the North 
  Ukrainian skies. It’s operation area was Bucha-Irpin-Makariv-Busova 
  (Kyiv’s Oblast).`,
	tab1_card2_3: `It’s proved that this particular jet was involved 
  in russian war crimes against civilians (by striking infrastructure 
  and non-military building in villages & towns).`,
	tab1_card3_1: `Sukhoi’s Endspiel`,
	tab1_card3_2: `
  During the Battle of Kyiv, the bomber found its end in Makariv 
  district (West to Kyiv). A masterly performed operation 
  by Ukrainian defenders persuaded this beast to land 
  off an original runway somewhere in the forest.`,
	tab1_bottom: `Your donations will help Ukrainian soldiers 
  to hunt down more russian iron-beasts, those which declared 
  war against civil population. The support to our defenders 
  is an essential part of saving civilian lives as well as 
  civilization itself.`,
	tab1_next_button: "Types of Presents",
	tab2_header: "Types of Presents",
	tab2_center_card_1: "In stock:",
	tab2_center_card_2: "Coming soon:",
	tab2_center_card_3: `■ Type E - the most unique part of a marked airframe.`,
	tab2_type_a_title: "Type А",
	tab2_type_a_1: "Size S piece of the airframe.",
	tab2_type_b_title: "Type B",
	tab2_type_b_1: "Size M piece of the airframe.",
	tab2_type_c_title: "Type C",
	tab2_type_c_1:
		"Size M piece of the airframe with russian markers and military stamps.",
	tab2_type_c_2: `Unique military marks, id numbers, tech statements written
  down by russian engineers.`,
	tab2_type_d_title: "Type D",
	tab2_type_d_1: "Size L piece of the airframe with Unique military marks",
	tab2_type_d_2: `Id numbers, tech statements written down by russian engineers`,
	tab2_next_button: "How to get a present?",
	tab3_header: "How to get a present?",
	tab3_card1_1: `Кожен долар ваших донатів дуже важливий для нас, і всі кошти в
повному обсязі йдуть на забезпечення потреб Збройних Сил
України та наближають перемогу над російськими окупантами. `,
	tab3_card1_2: `Але найщедрішим благодійникам ми даруємо подарунок -
«Маленький шматочок історичних українських перемог»`,
	tab3_card500_1: `Those who donated`,
	tab3_card500_2: `Will receive «A glorious particle of Ukrainian triumph».`,
	tab3_card500_3: `The Type A present`,
	tab3_card500_4: `and more`,
	tab3_card1000_1: `Those who donated`,
	tab3_card1000_2: `Will receive «A glorious particle of Ukrainian triumph».`,
	tab3_card1000_3: `The Type B present`,
	tab3_card1000_4: `and more`,
	tab3_card2000_1: `Those who donated`,
	tab3_card2000_2: `Will receive «A glorious particle of Ukrainian triumph».`,
	tab3_card2000_3: `The Type C present`,
	tab3_card2000_4: `and more`,
	tab3_card5000_1: `Those who donated`,
	tab3_card5000_2: `Will receive «A glorious particle of Ukrainian triumph».`,
	tab3_card5000_3: `The Type D present`,
	tab3_card5000_4: `and more`,
	tab3_card2_1: `You can donate in any convenient way, using information
	in the “Requisites” section. After completing the donation, sent an 
	email to the uarmy.help2022@gmail.com with an address & the personal 
  information. The present will be delivered anywhere you want!`,
};
