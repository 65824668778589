export const focusEn = {
	text0: "Done",
	text1: "Our Main Focus for",
	text2: "May-June 2022",
	text3: "Training and Drilling of Drones’ Operators Center.",
	text4: "Trained",
	text5: "Drones’ Operators",
	text6: "Combat Missions",
	text7: "Accomplished",
	text8: "Our Main Focus for",
	text9: "October-December 2022",
	text10: "Supplies for winter campaign",
	text11: `Thermal clothing, potbelly stove, heaters, electric generators, etc.`,
	text12: "Our Main Focus for",
	text13: "January 2023 - now",
	text14: "We collect the above items",
};
